<template>
    <div>
                <div class="frame-content-bottom-icon">
                  <div class="content-bottom-icon">
                    <!-- <img src="@/static//logos/company.png" class="content-bottom-icon-img"> -->
                  </div>
                  <div class="frame-content-bottom-item">
                    <div class="content-bottom-item">
                      <div class="content-bottom-title">公司</div>
                      <template v-for="item in company" :key="item">
                        <div class="content-bottom-text">{{item}}</div>
                      </template>
                    </div>

                    <div class="content-bottom-item">
                      <div class="content-bottom-title">统计数据</div>
                      <template v-for="item in date" :key="item">
                        <div class="content-bottom-text">{{item}}</div>
                      </template>
                    </div>

                    <div class="content-bottom-item">
                      <div class="content-bottom-title">资源</div>
                      <template v-for="item in souce" :key="item">
                        <div class="content-bottom-text">{{item}}</div>
                      </template>
                    </div>

                    <div class="content-bottom-item">
                      <div class="content-bottom-title">联系我们</div>
                      <div class="content-bottom-icons">
                        <template v-for="item in image" :key="item">
                          <div class="content-bottom-icons-frame">
                            <img :src="item" class="content-bottom-icons-img">
                          </div>
                      </template>
                      </div>
                      
                    </div>

                  </div>
                </div>
              </div>
</template>
<script>
export default {
    data(){
        return{
            company:["关于","职业生涯","关于我们"],
            date:["排名","活动"],
            souce:["帮助中心","公众号","博客","通讯"],
            image:[
            require("../static/image/image1.png"),
            require("../static/image/image2.png"),
            require("../static/image/image3.png"),
            require("../static/image/image4.png"),
            require("../static/image/image5.png"),
            require("../static/image/image6.png"),
            ],
        }
    }
}
</script>
<style scoped>
.frame-content-bottom-icon{
  padding-top: 30px;
  display: flex;
}
.content-bottom-icon{
  width: 45%;
  margin-left: 50px;
}
.content-bottom-icon-img{
  width: 90%;
  margin-top: -50px;
  /* height: 73px; */
}
.content-bottom-icon-img::before {
  content: "";
  display: block;
  padding-bottom: 100%; /* 保持高度与宽度相等 */
}
.frame-content-bottom-item{
  display: flex;
  width: 80%;
  padding: 20px;
}
.content-bottom-item{
  width: 25%;
}
.content-bottom-title{
  font-size: 14px;
font-weight: 400;
letter-spacing: 0px;
line-height: 19.21px;
margin-bottom: 8px;
background: linear-gradient(to right,rgba(115, 224, 169, 1) ,rgba(91, 104, 223, 1) ,rgba(91, 104, 223, 1));
-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.content-bottom-text{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 19.21px;
  color: rgba(31, 29, 43, 1);
  margin: 4px 0px;
}
.content-bottom-icons{
  display: flex;
  flex-wrap: wrap;
}
.content-bottom-icons-frame{
  /* width: 42px; */
  width: 20%;
  /* padding-bottom: 17%; */
  /* height: 50%; */
  /* height: 42px; */
  opacity: 1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8%;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 16px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right,rgb(215, 237, 227), rgb(182, 217, 200)), linear-gradient(90deg, rgba(115, 224, 169, 1), rgb(67, 82, 217));
}
.content-bottom-icons-frame::before {
  content: "";
  display: block;
  padding-bottom: 100%; /* 保持高度与宽度相等 */
}
.content-bottom-icons-img{
  width: 23px;
  height: 23px;
  /* object-fit: cover; */
}
</style>