<template>
    <div class="page">
      <div class="leftBox">
        <!-- <h1>访客</h1> -->
        <div class="chatBox">
            <!-- <div class="chatBox-title">
                <div class="avator">
                    <img src="../assets/images/image.png" class="avator-size">
                </div>
                <div class="title">蓝狐科技-官方咨询</div>
            </div> -->
          <div ref="chatRecordBox" class="chatRecordBox">
            <!-- <div class="chatBox-title">
                <div class="avator">
                    <img src="../assets/images/image.png" class="avator-size">
                </div>
                <div class="title">蓝狐科技-官方咨询</div>
            </div> -->
            <div v-for="(item, index) in chatRecordList" :key="index">
              <RightRole
                v-if="item.role === 'customer'"
                type="text"
                :content="item.content"
                :avatarURL="visitorAvatarURL"
                @openMenu="openMenu($event, index, true)"
              />
              <LeftRole
                v-if="item.role === 'service'"
                type="text"
                :content="item.content"
                :avatarURL="serviceAvatarURL"
                @openMenu="openMenu($event, index)"
              />
            </div>
          </div>
          <div class="toolBox">
            <ChooseEmotion @getEmo="chooseEmo($event, 'visitor')" />
<!--            <img-->
<!--              @click="chooseImg('visitor')"-->
<!--              class="imgIcon"-->
<!--              src="../assets/images/image-icon.svg"-->
<!--            />-->
            <!-- <img
              @click="chooseVideo('visitor')"
              class="videoIcon"
              src="@/assets/images/video-icon.svg"
            />
            <img
              @click="chooseAudio('visitor')"
              class="audioIcon"
              src="@/assets/images/music-icon.svg"
            /> -->
          </div>
          <div
            ref="visitorSendContentBox_Ref"
            contenteditable="true"
            class="sendContentBox"
          >
          </div>
          <div class="btnBox">
            <button @click="visitorSend" size="small" class="btn" :class="{ 'active': isButtonActive }">发送</button>
          </div>
        </div>

        <el-dialog
          title="图片预览"
          v-model:visible="showPreviewImgWin"
          style="text-align: center;width: 600px"
        >
          <el-image
            style="height: 400px"
            :preview-src-list="[imgSrc]"
            :src="imgSrc"
            fit="contain"
          />
        </el-dialog>
  
        <!-- 右键快捷菜单 -->
        <ul
          v-show="quickMenuVisible"
          :style="{ left: quickMenu_left + 'px', top: quickMenu_top + 'px' }"
          class="contextmenu"
        >
          <li v-show="ifCan_rollBack" @click="rollBack">撤回</li>
          <li @click="copy">复制</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import LeftRole from "../components/leftRole.vue";
  import RightRole from "../components/rightRole.vue";
  import ChooseEmotion from "../components/chooseEmotion.vue";
  import visitorAvatarURL from "@/assets/images/visitor-icon.svg";
  import serviceAvatarURL from "@/assets/images/customer-icon.svg";
  
  export default {
    watch: {
      quickMenuVisible(value) {
        if (value) {
          document.body.addEventListener("click", this.closeMenu);
        } else {
          document.body.removeEventListener("click", this.closeMenu);
        }
      },
      dialogTableVisible(value) {
        if(value) {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        } else {
          this.$emit('clearUnread')
        }
      },
    },
    components: { LeftRole, RightRole, ChooseEmotion },
    mounted() {
      let that = this;
      this.connect()


      // 检查 LocalStorage 是否已经有保存的 client_id
      if (localStorage.getItem('visitor_id')) {
        this.visitor_id = localStorage.getItem('visitor_id');
      } else {
        // 如果没有保存的 client_id，生成一个新的，并保存到 LocalStorage 中
        this.visitor_id = this.generateClientId();
        localStorage.setItem('visitor_id', this.visitor_id);
      }
      // this.getChatHistory();

      // 点击图片放大预览
      window.addEventListener("click", function (e) {
        let { target } = e;
        let nodeName = target.nodeName;
  
        if (nodeName === "IMG") {
          // 获取自定义的属性 preview
          let preview = target.getAttribute("preview");
          // 无 preview 属性的图片，不支持点击放大预览
          if (!preview) {
            return;
          }
          that.imgSrc = target.currentSrc;
          that.showPreviewImgWin = true;
        }
      });

      // 监听回车键事件
      this.$refs.visitorSendContentBox_Ref.addEventListener('keydown', this.handleKeyDown);
    },
    beforeDestroy() {
      this.$refs.visitorSendContentBox_Ref.removeEventListener('keydown', this.handleKeyDown);
    },
    data() {
      return {
        ifCan_rollBack: false,
        index: null,
        quickMenu_left: 0,
        quickMenu_top: 0,
        quickMenuVisible: false,
        imgSrc: "",
        showPreviewImgWin: false,
        visitorAvatarURL,
        serviceAvatarURL,
        chatRecordList: [],
        visitorPlaceholderVisible: true,
        ws: null,
        client_id: '',
        client_list: [],
        room_id: 1,
        visitor_id: '',
        isButtonActive: false, // 默认按钮背景颜色状态为 false
        unread: 0,//未读聊天记录
      };
    },
    props :{
      dialogTableVisible: {
        default: true,
        type: Boolean
      }
    },
    methods: {
      handleKeyDown(event) {
        // if (event.key === 'Enter') {
          if (event.keyCode === 13) {
          event.preventDefault(); // 阻止默认的回车行为（换行）
          this.visitorSend(); // 调用发送消息的方法
        }
      },
      // 撤回消息
      rollBack() {
        this.chatRecordList.splice(this.index, 1);
      },
      // js 点击复制到剪贴板函数
      copy() {
        let content = this.chatRecordList[this.index].content;
  
        if (window.clipboardData) {
          window.clipboardData.setData("text", content);
        } else {
          (function (content) {
            document.oncopy = function (e) {
              e.clipboardData.setData("text", content);
              e.preventDefault();
              document.oncopy = null;
            };
          })(content);
          document.execCommand("Copy");
        }
      },
      // 显示右键快捷菜单
      openMenu(e, index, ifCan_rollBack) {
        this.ifCan_rollBack = ifCan_rollBack;
        this.index = index;
        this.quickMenu_top = e.pageY;
        this.quickMenu_left = e.pageX;
        this.quickMenuVisible = true;
      },
      // 隐藏右键快捷菜单
      closeMenu() {
        this.quickMenuVisible = false;
      },
      // 根据角色，确定编辑框
      getSendContentBox_Ref(role) {
        if (role === "visitor") {
          return "visitorSendContentBox_Ref";
        }
        if (role === "service") {
          return "serviceSendContentBox_Ref";
        }
      },
      // 在编辑框中插入内容
      sendContentBox_insert(role, insertContent) {
        let sendContentBox_Ref = this.getSendContentBox_Ref(role);
        let content = JSON.parse(
          JSON.stringify(this.$refs[sendContentBox_Ref].innerHTML)
        );
  
        let newContent = "";
  
        // 光标在编辑框内时
        if (window.getSelection().anchorNode) {
          // 获取光标在编辑框中的下标
          let startIndex = window.getSelection().anchorOffset;
          let ednIndex = window.getSelection().focusOffset;
  
          newContent =
            content.substring(0, startIndex) +
            insertContent +
            content.substring(ednIndex);
        } else {
          // 光标不在编辑框内时
          newContent = content + insertContent;
        }
  
        this.$refs[sendContentBox_Ref].innerHTML = newContent;
      },
      chooseEmo(emo, role) {
        this.sendContentBox_insert(role, emo);
      },
      // 访客发送消息
      visitorSend() {
        let content = this.$refs.visitorSendContentBox_Ref.innerHTML;
        if (!content) {
          this.$message({
            message: "请输入发送内容！",
            type: "warning",
          });
          return;
        }
  
        this.chatRecordList.push({
          role: "customer",
          content: content,
          type: "text",
        });
        this.ws.send(JSON.stringify({
          type: 'customerSay',
          visitor_id: this.visitor_id,
          content: content,
        }))
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        // 发送后，清空发送框的内容
        this.$refs.visitorSendContentBox_Ref.innerHTML = "";

        // 切换按钮背景颜色状态
        this.isButtonActive = true;
        setTimeout(() => {
          this.isButtonActive = false;
        }, 100); // 300ms 后恢复默认背景颜色
      },
      // 选择图片
      chooseImg(role) {
        let that = this;
        let input = document.createElement("input");
        input.setAttribute("type", "file");
        // 支持多选
        input.setAttribute("multiple", "multiple");
        input.accept = "image/*";
        input.addEventListener("change", (e) => {
          let file = e.path[0].files[0];
          // 浏览器兼容性处理（有的浏览器仅存在 Window.URL）
          const windowURL = window.URL || window.webkitURL;
          // createObjectURL 函数会根据传入的参数创建一个指向该参数对象的URL
          let filePath = windowURL.createObjectURL(file);
  
          let tmp_imgDom = document.createElement("img");
          tmp_imgDom.setAttribute("src", filePath);
          tmp_imgDom.setAttribute("height", 30);
          tmp_imgDom.setAttribute("preview", true);
          tmp_imgDom.style.cursor = "pointer";
  
          let tmp_divDom = document.createElement("div");
          tmp_divDom.appendChild(tmp_imgDom);
  
          that.chatRecordList.push({
            role: role === "visitor" ? "访客" : "客服",
            content: tmp_divDom.innerHTML,
            type: "img",
          });
        });
        input.click();
      },
      // 选择视频
      chooseVideo(role) {
        let that = this;
        let input = document.createElement("input");
        input.setAttribute("type", "file");
        // 支持多选
        input.setAttribute("multiple", "multiple");
        input.accept = "video/*";
        input.addEventListener("change", (e) => {
          let file = e.path[0].files[0];
          // 浏览器兼容性处理（有的浏览器仅存在 Window.URL）
          const windowURL = window.URL || window.webkitURL;
          // createObjectURL 函数会根据传入的参数创建一个指向该参数对象的URL
          let filePath = windowURL.createObjectURL(file);
  
          let tmp_videoDom = document.createElement("video");
  
          tmp_videoDom.setAttribute("src", filePath);
          tmp_videoDom.setAttribute("height", 100);
          tmp_videoDom.setAttribute("controls", "controls");
          tmp_videoDom.style.cursor = "pointer";
  
          let tmp_divDom = document.createElement("div");
          tmp_divDom.appendChild(tmp_videoDom);
  
          that.chatRecordList.push({
            role: role === "visitor" ? "访客" : "客服",
            content: tmp_divDom.innerHTML,
            type: "video",
          });
        });
        input.click();
      },
  
      // 选择音频
      chooseAudio(role) {
        let that = this;
        let input = document.createElement("input");
        input.setAttribute("type", "file");
        // 支持多选
        input.setAttribute("multiple", "multiple");
        input.accept = "audio/*";
        input.addEventListener("change", (e) => {
          let file = e.path[0].files[0];
          // 浏览器兼容性处理（有的浏览器仅存在 Window.URL）
          const windowURL = window.URL || window.webkitURL;
          // createObjectURL 函数会根据传入的参数创建一个指向该参数对象的URL
          let filePath = windowURL.createObjectURL(file);
  
          let tmp_audioDom = document.createElement("audio");
  
          tmp_audioDom.setAttribute("src", filePath);
          tmp_audioDom.setAttribute("height", 30);
          tmp_audioDom.setAttribute("controls", "controls");
          tmp_audioDom.style.cursor = "pointer";
          let tmp_divDom = document.createElement("div");
          tmp_divDom.appendChild(tmp_audioDom);
  
          that.chatRecordList.push({
            role: role === "visitor" ? "访客" : "客服",
            content: tmp_divDom.innerHTML,
            type: "audio",
          });
        });
        input.click();
      },
      connect() {
        // 创建websocket
        // this.ws = new WebSocket("ws://47.96.138.240:7272");
        this.ws = new WebSocket("wss://www.yulianshuju.com/wss");
        // 当socket连接打开时，输入用户名
        this.ws.onopen = this.onopen;
        // 当有消息时根据消息类型显示不同信息
        this.ws.onmessage = this.onmessage;
        const self = this
        this.ws.onclose = function() {
          console.log("连接关闭，定时重连");
          self.connect();
        };
        this.ws.onerror = function() {
          console.log("出现错误");
        };
      },
      // 连接建立时发送登录信息
      onopen()
      {
        // 登录
        let login_data = JSON.stringify({
          type: 'login',
          visitor_id: this.visitor_id,
          client_name: '用户' + this.visitor_id,
          room_id: 1  // 假设默认房间 ID 是 1
        })
        console.log("websocket握手成功，发送登录数据:"+login_data);
        this.ws.send(login_data);
      },
      // 服务端发来消息时
      onmessage(e)
      {
        console.log('message')
        console.log(e.data);
        var data = JSON.parse(e.data);
        switch(data['type']){
          // 服务端ping客户端
          case 'ping':
            this.ws.send('{"type":"pong"}');
            break;
          // 登录 更新用户列表
          case 'login':
            // say(data['client_id'], data['client_name'],  client_name+' 加入了聊天室', data['time']);
            // flush_client_list();
            console.log(data['client_name']+"登录成功");
            break;
          // 发言
          case 'say':
            //{"type":"say","from_client_id":xxx,"to_client_id":"all/client_id","content":"xxx","time":"xxx"}
            say(data['from_client_id'], data['from_client_name'], data['content'], data['time']);
            break;
          case 'serviceSay':
            data.role = 'service'
            this.chatRecordList.push(data)
              this.$nextTick(() => {
                this.scrollToBottom();
              });
            console.log(this.chatRecordList)
            if(!this.dialogTableVisible) {
              this.$emit('addUnread',{})
            }
            break;
        }
      },
      // 生成唯一的 client_id （可以使用随机字符串或 UUID）
      generateClientId() {
        return 'xxxxxx-xxxx-4xxx-yxxx-xxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      },
      scrollToBottom() {
        const chatRecordBox = this.$refs.chatRecordBox;
        if (chatRecordBox) {
          chatRecordBox.scrollTop = chatRecordBox.scrollHeight;
        }
      },
      getChatHistory() {
        this.$axios.get('/chat.chatLog/getLogList', {
          params: {
            'visitor_id': this.visitor_id
          }
        }).then((res)=>{
          this.chatRecordList = res.data.data
          this.$nextTick(() => {
            this.scrollToBottom();
          });
          console.log(this.chatRecordList)
        }).catch((error)=>{
          console.error('Error fetching data:',error);
        });
      }
    },
  };
  </script>
  
  <style scoped >
  :deep(.el-dialog__headerbtn){
    background-color: red;
  }
  :deep(.bubble-right){
    border: none;
    background: rgba(235, 239, 255, 1);
  }
  .page {
    display: flex;
    justify-content: space-around;
  }
  .chatBox {
    width: 450px;
    /* padding: 10px; */
    /* background: #409eff; */
    border-radius: 18px;
  }
  .chatRecordBox {
    padding: 10px;
    height: 245px;
    padding-top: 10px;
    /*border-radius: 10px; */
    background: white;
    overflow: auto;
    border-bottom: 1px solid rgb(195, 187, 187);
  }
  .chatBox-title{
    display: flex;
    height: 60px;
    background: rgba(94, 117, 217, 1);
    border-radius: 18px 18px 0px 0px;
    align-items: center;
  }
  .avator{
    width: 45px;
    height: 45px;
    opacity: 1;
    border-radius: 26px;
    margin-left: 20px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 1);
  }
  .avator-size{
    width: 36px;
    height: 36px;
  }
  .title{
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 31.86px;
    color: rgba(255, 255, 255, 1);
    margin-left: 15px
  }

  .sendContentBox {
    height: 80px;
    padding: 0px 10px; 
    background: white;
    overflow: auto;
    outline: none;
    /* border-top: 1px solid rgb(195, 187, 187); */
  }
  .btn{
    width: 68px;
    height: 27px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(217, 230, 255, 1);
    border: none;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(255, 255, 255, 1);
  }
  .btn.active {
    background: rgba(35, 109, 255, 1) 100%; /* 改变为新的背景颜色 */
  }
  .btnBox {
    padding-bottom: 15px;
    text-align: right;
  }

  h1 {
    line-height: 40px;
    font-weight: bold;
  }
  .toolBox {
    /* padding: 4px; */
    background: white;
    /* margin-top: 10px; */
    display: flex;
    align-items: center;
  }
  
  img {
    cursor: pointer !important;
    display: inline-block;
  }
  .imgIcon {
    margin-left: 8px;
    height: 20px;
    cursor: pointer;
  }
  .videoIcon {
    width: 22px;
    cursor: pointer;
    margin-left: 4px;
  }
  .audioIcon {
    width: 18px;
    cursor: pointer;
    margin-left: 4px;
    border-radius: 4px;
  }
  /* 右键快捷菜单的样式 */
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  }
  
  .contextmenu li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
  }
  
  .contextmenu li:hover {
    background: #eee;
  }
  </style> 
  
  