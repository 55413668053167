<template>
  <div class="content-app">
    <div class="content-app-title">哪些行业需要做APP</div>
    <span class="content-app-text">通过定制App，可以确保用户界面（UI）和用户体验（UX）完全符合目标用户群体的需求和偏好。
        这包括个性化的设计、直观的操作流程以及符合用户习惯的功能布局，从而提升用户满意度和忠诚度。
      </span>
    <div class="frame-content-app">
      <div class="content-app-row">

        <template v-for="item in icons" :key="item">
          <div class="frame-icon">
            <div>
              <img :src="item.image" class="icon-img">
              <div class="icon-text">{{ item.title }}</div>
            </div>
          </div>
        </template>

      </div>
    </div>

    <div class="content-app-click">
      <button class="app-click-text" @click="goToCase">其他行业APP</button>
    </div>

    <div class="show-frame">
      <div class="show-title">案例展示</div>
      <div class="show-content">定制App的客户案例非常丰富，涵盖了各行各业，从零售、餐饮到教育、医疗等多个领域</div>
      <div class="carousel-bg">
        <carousel-3d :autoplay="true" :autoplay-timeout="2000" :height="370" :width="280" :perspective="20" :space="260">
          <slide v-for="(slide, i) in source" :index="i" :key="slide.id">
            <div class="carousel-card" @click="btnClick(i)">
              <div class="card-bg">
                <div class="card-icon">
                  <img :src="slide.image" style="width: 150px;height: 150px;">
                </div>
                <div class="card-icon-text">{{slide.name}}</div>
              </div>
              <div class="card-bottom">{{ slide.description}}</div>
            </div>
          </slide>
        </carousel-3d>
      </div>
      <div class="content-app-click">
        <button class="app-click-text" @click="dialogVisible = true">立即拥有一款APP</button>
        <el-dialog v-model="dialogVisible" width="500">
          <img src="../static/image/get-bg.png" class="get-bg">
          <div class="form">
            <el-form :model="form" label-width="auto" style="max-width: 600px">
              <el-form-item label="姓名:">
                <el-input placeholder="请输入您的称呼方式" v-model="form.name" />
              </el-form-item>
              <el-form-item label="联系电话:">
                <el-input placeholder="请输入您的联系电话" v-model="form.mobile" />
              </el-form-item>
              <el-form-item label="开发需求:">
                <el-select placeholder="" v-model="form.type">
                  <!-- <el-option label="Zone one" value="shanghai" />
                  <el-option label="Zone two" value="beijing" /> -->
                  <el-option
                    v-for="item in design"
                    :key="item.id"
                    :label="item.name"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="预算:">
                <el-input placeholder="请输入您的预算" v-model="form.price" />
              </el-form-item>
            </el-form>
            <div class="submit">
              <el-button @click="addbj">提交</el-button>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>

    <div class="content-strengths">
      <div class="strengths-title">我们的优势</div>
      <div class="frame-strengths-content">
        <template v-for="item in strengths" :key="item">
          <div class="strengths-frame">
            <div class="icons">
              <img :src="item.img" class="img">
            </div>
            <div class="strengths-content">{{item.title}}</div>
            <div class="strengths-text">{{item.content}}</div>
          </div>
        </template>
      </div>
      <div class="strengths-title">专业团队，铸就非凡</div>
      <div class="title-item">以专业之力，创无限可能，我们的团队无所不能。</div>
      <div class="demo-video">
        <div class="demo">
          <video-player :src="videoSrc" :options="playerOptions" :volume="0.6"></video-player>
        </div>
      </div>
      <div class="content-app-click">
        <div class="app-click-text" style="width: 300px;">探索市场</div>
      </div>

      <contentBottom></contentBottom>


    </div>

  </div>

</template>

<script>
import { Carousel3d, Slide } from 'vue3-carousel-3d';
import ContentBottom from './contentBottom.vue';
import {getCompleteImageUrl} from '../imgurl.js'
import { th } from 'element-plus/es/locale/index.mjs';
import {ElMessageBox} from "element-plus";
// import SphereComponent from './SphereComponent.vue';

export default {
  components:{
    Carousel3d,
    Slide,
    ContentBottom,
    // SphereComponent,
  },
  data(){
    return{
      videoSrc:"",
      playerOptions:{

        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: 'any', // 如果true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controls: true,
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      },
      dialogVisible: false,
      form: {
        name: '',
        mobile: '',
        type: '',
        price: ''
      },
      design:[],
      strengths:[
        {
          img:require("@/static/icon/1.png"),
          title:"独特性与个性化",
          content:"定制性强：软件开发定制平台能够根据企业的具体需求和业务流程量身定制软件解决方案，从而精准满足企业的独特需求。这种个性化定制的优势使得软件能够更好地适应企业的实际情况，提升工作效率和业务运营水平。"
        },
        {
          img:require("@/static/icon/2.png"),
          title:"技术选型和架构先进",
          content:"定制开发的软件通常采用最新的技术和架构，这使得软件具有更强的可扩展性和灵活性，便于后期的维护和升级。这不仅有助于提升软件的性能，还能确保软件能够跟上技术发展的步伐。"
        },
        {
          img:require("@/static/icon/3.png"),
          title:"费用效益",
          content:"尽管软件定制开发的初始成本可能较高，但考虑到长期来看，能够提升效率、降低资源浪费，从而实现更好的费用效益。此外，定制开发的软件可以根据企业的发展进行功能扩展和升级，确保软件始终适应企业的发展方向。"
        },
        {
          img:require("@/static/icon/4.png"),
          title:"数据安全与隐私保护",
          content:"定制软件专为特定企业环境设计，减少了数据泄露的风险，提高了数据安全性。同时，可以根据企业的安全需求进行定制化设计，增强软件的安全防护措施，确保敏感数据得到更好的保护。"
        },
      ],
      source:[
        {
          id:1,
          src:require("@/static/anli/canyin.png"),
          title:"餐饮",
          content:"餐饮APP是面向餐饮企业的手机客户端，它为用户和餐饮企业提供了多种便捷的功能和服务。"
        },

        {
          id:1,
          src:require("@/static/anli/yiliao.png"),
          title:"医疗",
          content:"宠物APP是为宠物主人和爱好者提供多种服务的应用程序，它们通常包含多种功能以满足用户的不同需求。"
        },
        {
          id:1,
          src:require("@/static/anli/dianshang.png"),
          title:"电商",
          content:"电商APP，即电子商务应用程序，是基于移动端实现消费者网上购物、商户之间网上交易和在线电子支付以及各种商务活动、交易活动、金融活动和相关的综合服务活动的一种新型商业运营模式。"
        },
        {
          id:1,
          src:require("@/static/anli/jiaoyu.png"),
          title:"教育",
          content:"教育App（教育移动互联网应用程序，简称教育移动应用）是指以教职工、学生、家长为主要用户，以教育、学习为主要应用场景，服务于学校教学与管理、学生学习与生活以及家校互动等方面的互联网移动应用程序。"
        },
        {
          id:1,
          src:require("@/static/anli/chongwu.png"),
          title:"宠物",
          content:"宠物APP是为宠物主人和爱好者提供多种服务的应用程序，它们通常包含多种功能以满足用户的不同需求。"
        }
      ],
      icons:[
        {
          image:require("@/static/image/jinrong.png"),
          title:"金融"
        },
        {
          image:require("@/static/image/zhibo.png"),
          title:"直播"
        },
        {
          image:require("@/static/image/jiaoyu.png"),
          title:"教育"
        },
        {
          image:require("@/static/image/zhaopin.png"),
          title:"招聘"
        },
        {
          image:require("@/static/image/jinrong1.png"),
          title:"金融"
        },
        {
          image:require("@/static/image/jinrong2.png"),
          title:"金融"
        },
        {
          image:require("@/static/image/tuangou.png"),
          title:"团购"
        },
        {
          image:require("@/static/image/fuchi.png"),
          title:"扶持"
        },
        {
          image:require("@/static/image/sheying.png"),
          title:"摄影"
        },
        {
          image:require("@/static/image/guanlihoutai.png"),
          title:"管理后台"
        },
        {
          image:require("@/static/image/qianduankaifa.png"),
          title:"前端开发"
        },
        {
          image:require("@/static/image/shejiao.png"),
          title:"社交"
        },
        {
          image:require("@/static/image/houtai.png"),
          title:"后台"
        },
        {
          image:require("@/static/image/yinyue.png"),
          title:"音乐"
        },
        {
          image:require("@/static/image/tiyu.png"),
          title:"体育"
        },
        {
          image:require("@/static/image/baokao.png"),
          title:"报考"
        },
        {
          image:require("@/static/image/shouyou.png"),
          title:"手游"
        },
        {
          image:require("@/static/image/tushu.png"),
          title:"图书"
        },
        {
          image:require("@/static/image/youer.png"),
          title:"幼儿"
        },
        {
          image:require("@/static/image/xiaochengxu.png"),
          title:"小程序"
        },
        {
          image:require("@/static/image/chuxing.png"),
          title:"出行"
        },
      ],
    }
  },
  created() {
    this.sele()
    this.selvideo()
    this.s()
  },
  methods:{
    //项目类别全查
    s(){
      this.$axios.get('/index/dict',{
        params : {
          type: 'development_type,development_price'
        }
      }).then((res)=>{
        // this.design=res.data
        this.design=res.data.data.development_type
        this.price = res.data.data.development_price
        console.log(res.data)
      })
    },
    //轮播图全查
    //全查
    sele(){
      this.$axios.get('/case.caseType/all',
        {
        }).then((res) => {
        // this.source =res.data.map(item=>({...item, carousel:getCompleteImageUrl(item.carousel)}));
        this.source = res.data.data
      }).catch((error)=>{
        console.error('Error fetching data:',error)
      })
    },

    goToCase(){
      this.$router.push({
        path: '/Case'
      });
    },



    btnClick(index) {
      const selectedSlide = this.source[index]; // 获取对应的 slide 对象
      const id = selectedSlide.id; // 获取该 slide 的 id

      // 打印 id，便于调试
      console.log('Selected Slide ID:', id);

      // 跳转到指定页面，同时传递 id
      this.$router.push({
        path: '/Case',
        query: { id: id },
        hash: '#case-kinds'
      });
    },





    //视频查询
    selvideo(){
      // this.$axios.get('/video/getAll',{
      this.$axios.get('/index/getWebsite',{
      }).then((res) => {
        // this.videoSrc = getCompleteImageUrl(res.data[0].video);
        this.videoSrc = res.data.data.company_video
        console.log(res.data)
      }).catch((error)=>{
        console.error('Error fetching data:',error)
      })
    },

    //添加项目报价
    addbj() {
      this.$axios.post('/customer.customerRequirement/add', {
        name: this.form.name,
        type: this.form.type,
        price: this.form.price,
        mobile: this.form.mobile
      }).then((res) => {
        if (res.data.code === 1) {
          ElMessageBox.alert('已经了解你的需求，稍后会有专业的人联系您！！！！', '报价', {
            confirmButtonText: 'OK',
          });
          this.mobile='';
          this.dialogVisible = false
        } else {
          this.$message.error(res.data.msg);
        }
        console.log(res);
      }).catch((error) => {
        console.error("请求失败", error);
      });
    },
  }
}
</script>
<style scoped>
.content-app{
  padding: 20px;
  background: radial-gradient(60% 50% at 80% 50%,rgb(176, 167, 234),transparent),
  radial-gradient(100% 40% at left 40%,rgb(160, 228, 193),transparent),
  radial-gradient(60% 50% at left 60%,rgb(176, 167, 234),transparent),
  radial-gradient(80% 50% at right bottom,rgb(160, 228, 193),transparent);
  /* background: linear-gradient(
      7deg,
      rgba(115, 224, 169, 1),
      rgba(255, 0, 0, 0) 60.71%
    ),
    linear-gradient(344deg, rgba(77, 0, 255, 1), rgba(0, 0, 255, 0) 50.71%); */
}
.content-app-title{
  font-size: 36px;
  line-height: 49.39px;
  color: rgba(31, 29, 43, 1);
  text-align: center;
  margin-top: 20px
}
.content-app-text{
  display: flex;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.frame-content-app{
  padding: 20px;
}
.content-app-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.frame-icon{
  width: 13%;
  height: 177px;
  opacity: 0.6;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
}
.icon-img{
  width: 80px;
  height: 80px;
}
.icon-text{
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: center;
}
.content-app-click{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 60px;
}
.app-click-text{
  width: 400px;
  height: 40px;
  opacity: 1;
  border-radius: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, rgba(115, 224, 169, 1) 0%, rgba(0, 87, 252, 1) 100%);
  font-size: 20px;
  font-weight: 500;
  line-height: 41.16px;
  color: rgba(255, 255, 255, 1);
  border: none;
}
:deep(.el-dialog){
  --el-dialog-padding-primary:0
}
.get-bg{
  width: 100%;
}
.form{
  padding: 20px;
}
.submit{
  display: flex;
  justify-content: center;
}
:deep(.el-button){
  width: 100px;
  color: #fff;
  background: linear-gradient(to right, rgb(141, 158, 241), rgb(56, 130, 233));
}
.show-title{
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 49.39px;
  color: rgba(31, 29, 43, 1);
  text-align: center;
  margin-top: 60px;
}
.show-content{
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 27.44px;
  color: rgba(56, 56, 56, 1);
  text-align: center;
  margin-top: 10px;
}
.carousel-bg{
  width: 100%;
}
.show-carousel{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.carousel-card{
  background: white;
  height: 100%;
}
.carousel-3d-slide{
  border-style: none;
}
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(n) {
  background-color: white;
}
.el-carousel{
  height: 350px;
}
.el-carousel__item--card{
  width: 50%;
  height: 350px;
}
.el-carousel__container{
  width: 2000px;
}

.card-bg{
  padding-top: 40px;
  height: 230px;
  background: linear-gradient(151.2deg, rgba(0, 187, 255, 1) 0%, rgba(0, 155, 244, 1) 100%);
}
.card-icon{

  display: flex;
  justify-content: center;
  align-items: center
}
.card-icon-text{
  font-size: 40px;
  font-weight: 400;
  line-height: 49.39px;
  text-align: center;
  margin-top: 20px;
  color: rgba(255, 255, 255, 1);
}
.card-bottom{
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 13px;
  color: rgba(128, 128, 128, 1);
}
.demo{
  width: 900px;

}
.demo-video{
  display: flex;
  justify-content: center;
  margin: 70px;
}
.content-strengths{
  padding: 40px 70px;
  /* background: radial-gradient(60% 100% at 80% 20%,rgb(176, 167, 234),transparent),
  radial-gradient(90% 90% at center bottom,rgb(160, 228, 193),transparent); */
  /* background: linear-gradient(
      -42deg,
      rgba(115, 224, 169, 1),
      rgba(255, 0, 0, 0) 80.71%
    ),
    linear-gradient(340deg, rgba(77, 0, 255, 1), rgba(0, 0, 255, 0) 65.71%); */
}
.strengths-title{
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 49.39px;
  color: rgba(31, 29, 43, 1);
  text-align: center;
}
.frame-strengths-content{
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  margin-top: 20px;
  margin-bottom: 70px;
}
.strengths-frame{
  width: 17%;
  margin: 0px 50px;
}
.icons{
  display: flex;
  justify-content: center;
}
.img{
  width: 45px;
  height: 45px;

}
.strengths-content{
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24.7px;
  color: rgba(31, 29, 43, 1);
  text-align: center;
  margin: 10px 0px;
}
.strengths-text{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 19.21px;
  color: rgba(31, 29, 43, 1);
}
.title-item{
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 27.44px;
  color: rgba(56, 56, 56, 1);
  text-align: center;
  margin-top: 10px;
}
</style>