<template>
    <div>
        <div class="frame">
            <div class="CS">
              <el-badge :value="unread" :hidden="unread === 0" class="item">
                <div @click="dialogTableVisible = true">
                  <img src="@/static/CS/CS.png" class="CS-avator">
                  <div class="link">联系客服</div>
                </div>
              </el-badge>
                <el-dialog
                 v-model="dialogTableVisible" 
                 :show-close="false"
                  @click="dialogOverflowVisible = true"
                   draggable>
                    <template #header> 
                        <div class="chatBox-title">
                        <div class="avator">
                            <img src="../static/logos/anhuiyulianshujuyouxiangongsi02.png" class="avator-size">
                        </div>
                        <div class="title">羽链数据-官方咨询</div>
                        <div class="icon" @click="dialogTableVisible = false">
                            <img src="@/static/icon/close.png" class="img">
                        </div>
                    </div>
                    </template>
                    <IndexBox :dialogTableVisible="dialogTableVisible" @addUnread="addUnRead()" @clearUnread="clearUnread()"></IndexBox>
                </el-dialog>
                <!-- <div class="line"></div>
                <img src="@/static/CS/price.png" class="get-price">
                <div class="link">获取报价</div> -->
            </div>
        </div>
    </div>
</template>
<script>
import IndexBox from '@/pages/IndexBox.vue';

export default {
    components:{
        IndexBox
    },
    data(){
        return{
            dialogTableVisible:true,
            unread:0,
            // popupVisible: false,
        }
    },
    methods:{
        showPopup(){
            this.popupVisible = true;
        },
      addUnRead(){
          this.unread += 1;
      },
      clearUnread(){
          this.unread = 0;
      }
    }
}
</script>
<style scoped>
.frame{
    /* width: 5%; */
    width: 86px;
    height: 86px;
    opacity: 1;
    border-radius: 84px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
    position: fixed;
    right: 20px;
    top: 55%;
    z-index: 2;
}
.CS{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.CS-avator{
    width: 50px;
    height: 40px;
    margin-top: 10px;
}
.link{
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 23.17px;
    color: rgba(94, 117, 217, 1);
    text-align: center;
}
.line{
    width: 80%;
    height: 1px;
    margin: 5px 0px;
    border-bottom: 2px solid rgba(214, 212, 212, 1);
}
.get-price{
    width: 48px;
    height: 40px;
}


:deep(.el-dialog){
    border-radius: 20px;
    padding: 0px;
    width: 480px;
    position: fixed;
    right: 0px;
    bottom: 0px;
    margin: 0;
}
:deep(.el-overlay) {
  background-color: transparent; /* 设置遮罩层背景色为透明 */
}
.chatBox-title{
    display: flex;
    height: 60px;
    background: linear-gradient(to right,rgb(178, 150, 245),rgb(134, 229, 182));
    border-radius: 18px 18px 0px 0px;
    align-items: center;
  }
  .avator{
    width: 45px;
    height: 45px;
    opacity: 1;
    border-radius: 26px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 1);
  }
  .avator-size{
    width: 80%;
    height: 80%;
  }
  .title{
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 31.86px;
    color: rgba(255, 255, 255, 1);
    margin-left: 15px
  }
  .icon{

  }
  .img{
    width: 44px;
    height: 44px;
    position: absolute;
    right: 15px;
    top: 10px;
  }
</style>
<style>
.item .el-badge__content {
  font-size: 20px; /* 设置小圆点中数字的字号 */
  padding: 6px 6px; /* 设置小圆点的内边距 */
}
</style>